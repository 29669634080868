/* eslint-disable react/prop-types, no-use-before-define, no-empty-pattern */
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import FaqItem from './FaqItem';
import DeliveryMap from './DeliveryMap';
import DeliveryCosts from './DeliveryCosts';

const views = {
  p: ({ content }, index) => <p key={index}>{content}</p>,
  title: ({ value }, index) => <Helmet key={index} title={value} />,
  faqlist: ({ blocks }, index) => <div key={index}>{renderBlocks(blocks)}</div>,
  faqitem: (data, index) => <FaqItem key={index} {...data} />,
  deliveryMap: ({}, index) => <DeliveryMap key={index} />,
  deliveryCosts: ({}, index) => <DeliveryCosts key={index} />,
};

function renderBlocks(blocks) {
  return blocks.map((block, index) => {
    if (!views[block.type]) {
      if (process.env.NODE_ENV === 'production') {
        return null;
      }

      return (
        <div>
          Unexpected type
          {block.type}
        </div>
      );
    }

    return views[block.type](block, index);
  });
}

const BlockPage = ({ blocks }) => renderBlocks(blocks);

export default BlockPage;
