import React from 'react';
import { Link } from 'gatsby';
import Phone from 'components/Phone';
import './Footer.module.css';

const Footer = () => (
  <div styleName="root">
    <div styleName="box">
      <div styleName="links-box">
        <Link to="/about-us" styleName="link about-link">
          О сервисе
        </Link>
        <Link to="/deposit" styleName="link about-link">
          Депозиты
        </Link>
        <Link to="/privacy-policy-cookie-restriction-mode" styleName="link user-agreement-mobile">
          Пользовательское соглашение
        </Link>
      </div>
    </div>
    <div styleName="box">
      <div styleName="contacts">
        <span>по всем вопросам пишите или звоните:</span>
        <a href="mailto:client@moscowfresh.ru" styleName="link email">
          client@moscowfresh.ru
        </a>
        <Phone styleName="number" />
      </div>
    </div>
    <div styleName="box">
      <div styleName="about">
        <Link to="/privacy-policy-cookie-restriction-mode" styleName="link user-agreement-computer">
          Пользовательское соглашение
        </Link>
        <span>{`© 2015-${new Date().getFullYear()} MoscowFresh`}</span>
      </div>
    </div>
  </div>
);

export default Footer;
