import * as React from 'react';
import PropTypes from 'prop-types';
import IconArrow from './icons/Arrow';
import './FaqItem.module.css';

const FaqItem = ({ question, answer }) => {
  const [hover, setHover] = React.useState(false);
  const [opened, setOpened] = React.useState(false);

  const handleClick = () => (opened ? setOpened(false) : setOpened(true));

  return (
    /* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={handleClick}
      styleName={opened ? 'root opened' : 'root'}
      role="presentation"
    >
      <h2 styleName="question">
        {question}
        <IconArrow styleName="arrow" color={hover ? 'orange' : 'gray'} size={24} />
      </h2>
      <div styleName="content">{answer}</div>
    </div>
  );
};

FaqItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default FaqItem;
