import React from 'react';
import { Link } from 'gatsby';
import './Header.module.css';

const Header = () => (
  <div styleName="root">
    <Link to="/" styleName="back-link">
      <span styleName="back-text">Назад</span>
    </Link>
    <Link to="/" styleName="logo">
      MoscowFresh
    </Link>
    <div />
  </div>
);

export default Header;
