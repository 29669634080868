import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import YandexMap from './YandexMap';
import './DeliveryMap.module.css';

const DeliveryMap = () => {
  const data = useStaticQuery(
    graphql`
      query DeliveryMap {
        v5 {
          deliveryZones {
            bound {
              lat
              lng
            }
            deliveryCosts {
              itemSum
              value
            }
            mapFillColor
            mapStrokeColor
          }
        }
      }
    `
  );

  const onLoad = (map, ymaps) => {
    map.setZoom(9);

    data.v5.deliveryZones.forEach(z => {
      const coords = z.bound.map(l => [l.lat, l.lng]);
      const deliveryCost = z.deliveryCosts[0];

      const polygon = new ymaps.Polygon(
        [coords],
        {
          balloonContent: `от ${deliveryCost.itemSum}р, доставка ${deliveryCost.value}р`,
        },
        {
          fillColor: z.mapFillColor,
          strokeColor: z.mapStrokeColor,
          strokeWidth: 8,
        }
      );

      map.geoObjects.add(polygon);
    });
  };

  return <YandexMap center={{ lat: 55.73473112282035, lng: 37.43875681368813 }} onLoad={onLoad} styleName="map" />;
};

export default DeliveryMap;
