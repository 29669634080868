import React from 'react';
import PropTypes from 'prop-types';

let loaded = false;

const loadScript = (src, callback) => {
  if (loaded) {
    callback();
    return;
  }
  loaded = true;

  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = src;
  s.onload = () => {
    callback();
  };

  const t = document.getElementsByTagName('script')[0];
  t.parentNode.insertBefore(s, t);
};

const YandexMap = ({ onLoad, center, className }) => {
  const onReady = () => {
    const { ymaps } = window;

    ymaps.ready(() => {
      const map = new ymaps.Map(
        'map',
        {
          center: [center.lat, center.lng],
          zoom: 18,
          controls: [],
        },
        {
          suppressMapOpenBlock: true,
        }
      );

      onLoad(map, ymaps);
    });
  };

  React.useEffect(() => {
    loadScript('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=7e9fde79-9e7d-417f-8405-6025a5021b24', onReady);
  }, []);

  return <div id="map" className={className} />;
};

YandexMap.propTypes = {
  onLoad: PropTypes.func.isRequired,
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  className: PropTypes.string.isRequired,
};

export default YandexMap;
