import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Price from './Price';
import './DeliveryCosts.module.css';

const DeliveryCosts = () => (
  <StaticQuery
    query={graphql`
      query DeliveryCostsQuery {
        v5 {
          deliveryCosts {
            id
            itemSum
            value
            activeRange {
              from
              to
            }
          }
        }
      }
    `}
    render={data => {
      const { deliveryCosts } = data.v5;

      const renderItemLabel = (item, nextItem) =>
        nextItem && nextItem.itemSum ? (
          <>
            <Price value={item.itemSum} />
            <span> - </span>
            <Price value={nextItem.itemSum} />
          </>
        ) : (
          <>
            <span>от </span>
            <Price value={item.itemSum} />
          </>
        );

      const renderItems = () =>
        deliveryCosts.map((item, index) => {
          const nextItem = deliveryCosts[index + 1];
          return (
            <div key={item.id} styleName="item">
              {item.activeRange ? (
                <div styleName="free-delivery">Завтра бесплатная доставка</div>
              ) : (
                <>
                  <span>{renderItemLabel(item, nextItem)}</span>
                  <Price value={item.value} allowFree />
                </>
              )}
            </div>
          );
        });

      if (deliveryCosts.length > 0) {
        return (
          <div styleName="root">
            <div styleName="header">
              <div>Сумма заказа</div>
              <div>Стоимость</div>
            </div>
            {renderItems()}
          </div>
        );
      }

      return null;
    }}
  />
);

export default DeliveryCosts;
