import React from 'react';
import PropTypes from 'prop-types';
import withSizes from 'react-sizes';
import { Helmet } from 'react-helmet-async';

import Header from './Header';
import Footer from './Footer';
import './index.module.css';

const SSR_SCREEN_WIDTH = 3840; // 4k

const StaticLayout = ({ children, size }) => {
  const width = size.width || SSR_SCREEN_WIDTH;
  const mobile = typeof window !== 'undefined' && width <= 666;

  return (
    <React.Fragment>
      {mobile && (
        <Helmet>
          <meta name="viewport" content="width=666, user-scalable=no" />
        </Helmet>
      )}
      <div styleName="root">
        <Header />
        <div>{children}</div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

StaticLayout.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.object,
};

StaticLayout.defaultProps = {
  size: undefined,
};

export default withSizes(size => ({ size }))(StaticLayout);
